import "./App.css";
import React, { Suspense, useState } from "react";
import { Canvas } from "@react-three/fiber";
import LoadingScreen from "./pages/LoadingScreen";
import LandingPage from "./pages/LandingPage";
import OnlyHTML from "./pages/OnlyHTML";
import Camera from "./components/Camera";

function App() {
  const [welcomeVisible, setWelcomeVisible] = useState(false);
  const [breatheGuide, setBreathGuide] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(false);
  const [keyPad, setKeyPad] = useState({
    M: "Guide",
  });

  return (
    <>
      <OnlyHTML
        welcomeVisible={welcomeVisible}
        breatheGuide={breatheGuide}
        setBreathGuide={setBreathGuide}
        setWelcomeVisible={setWelcomeVisible}
        setAudioEnabled={setAudioEnabled}
        keyPad={keyPad}
      />
      <Canvas className="canvas" shadows={true}>
        <Suspense fallback={<LoadingScreen />}>
          <Camera />
          <LandingPage
            setWelcomeVisible={setWelcomeVisible}
            setKeyPad={setKeyPad}
            audioEnabled={audioEnabled}
            setAudioEnabled={setAudioEnabled}
          />
        </Suspense>
      </Canvas>
    </>
  );
}

export default App;
