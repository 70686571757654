import React, { useEffect } from "react";
import Logo from "../components/Logo";
import WelcomeMessage from "../components/WelcomeMesage";
import Tetradecagon from "../components/Tetradecagon";
import { gsap } from "gsap";
import { useProgress } from "@react-three/drei";

const OnlyHTML = ({
  welcomeVisible,
  breatheGuide,
  setAudioEnabled,
  setWelcomeVisible,
  setBreathGuide,
  keyPad,
}) => {
  const { loaded } = useProgress();
  useEffect(() => {
    if (breatheGuide) {
      let tlbreathingGuide = gsap.timeline({ repeat: -1 });

      tlbreathingGuide.to(".tetradecagon-polygon", {
        fill: "#3d404a",
        duration: 2,
      });
      tlbreathingGuide.to(
        ".breathing-guide-text",
        { innerHTML: "BREATHE IN", duration: 1 },
        "<"
      );
      tlbreathingGuide.to(".tetradecagon", { height: "45%", duration: 2 }, "<");
      tlbreathingGuide.to(
        ".tetradecagon-line-1",
        { stroke: "#fff", duration: 1 },
        "<"
      );
      tlbreathingGuide.to(
        ".tetradecagon-line-2",
        { stroke: "#fff", duration: 1 },
        ">"
      );
      tlbreathingGuide.to(
        ".tetradecagon-line-3",
        { stroke: "#fff", duration: 1 },
        ">"
      );
      tlbreathingGuide.to(
        ".breathing-guide-text",
        { innerHTML: "HOLD", duration: 1 },
        "<"
      );
      tlbreathingGuide.to(
        ".tetradecagon-line-4",
        { stroke: "#fff", duration: 1 },
        ">"
      );
      tlbreathingGuide.to(
        ".tetradecagon-line-5",
        { stroke: "#fff", duration: 1 },
        ">"
      );
      tlbreathingGuide.to(
        ".tetradecagon-line-6",
        { stroke: "#fff", duration: 1 },
        ">"
      );
      tlbreathingGuide.to(
        ".tetradecagon-line-7",
        { stroke: "#fff", duration: 1 },
        ">"
      );
      tlbreathingGuide.to(
        ".tetradecagon-line-8",
        { stroke: "#fff", duration: 1 },
        ">"
      );
      tlbreathingGuide.to(
        ".tetradecagon-line-9",
        { stroke: "#fff", duration: 1 },
        ">"
      );
      tlbreathingGuide.to(
        ".tetradecagon-line-10",
        { stroke: "#fff", duration: 1 },
        ">"
      );
      tlbreathingGuide.to(
        ".tetradecagon-polygon",
        { fill: "#0e111a", duration: 4 },
        ">"
      );
      tlbreathingGuide.to(
        ".breathing-guide-text",
        { innerHTML: "BREATHE OUT", duration: 1 },
        "<"
      );
      tlbreathingGuide.to(".tetradecagon", { height: "27%", duration: 4 }, "<");
      tlbreathingGuide.to(
        ".tetradecagon-line-11",
        { stroke: "#fff", duration: 1 },
        "<"
      );
      tlbreathingGuide.to(
        ".tetradecagon-line-12",
        { stroke: "#fff", duration: 1 },
        ">"
      );
      tlbreathingGuide.to(
        ".tetradecagon-line-13",
        { stroke: "#fff", duration: 1 },
        ">"
      );
      tlbreathingGuide.to(
        ".tetradecagon-line-14",
        { stroke: "#fff", duration: 1 },
        ">"
      );
      tlbreathingGuide.to(
        ".tetradecagon-test",
        {
          stroke: "#6c6c6c",
          stagger: 0.05,
        },
        "<"
      );
    }
  }, [breatheGuide]);

  useEffect(() => {
    if (loaded > 29) {
      gsap.to(".audio-selection", {
        opacity: 1,
        autoAlpha: 1,
        duration: 1,
        delay: 2,
      });
    }
  }, [loaded]);

  const handleAudioSelection = (selection) => {
    let tl = gsap.timeline({
      onComplete: () => {
        setBreathGuide(false);
        setWelcomeVisible(true);
        document.querySelector(".audio-selection").style.display = "none";
        document.querySelector(".all-2d-html").style.height = "27%";
        document.querySelector(".header").style.height = "100%";
        gsap.to(".keypad", { opacity: 1, duration: 2, delay: 6 });
      },
    });
    tl.to(".breathing-guide", { opacity: 0, duration: 2 }, "<");
    tl.to(".audio-selection", { opacity: 0, duration: 2 }, "<");
    tl.to(".all-2d-html", { background: "transparent", duration: 1 }, ">");
    setAudioEnabled(selection);
  };

  return (
    <>
      <div className="overlay-only">
        <div className="small-screen-message">
          <div className="small-screen-message-text">
            The device width is too small for this experience. Please rotate
            your device or switch to a bigger screen.
          </div>
        </div>
        <div className="for-vision">
          <div className="for-vision-column">
            <div className="vision-para">
              <span>In this vast expanse, where beings reside,</span>
              <span>Some conscious, others simply alive,</span>
              <span>
                I contemplate
                <span className="bold-letters">the gift or the curse,</span>
              </span>
              <span>Of awareness,</span>
              <span>a blessing, or a universe dispersed.</span>
            </div>
            <div className="vision-para">
              <span>In search of answers, my thoughts take flight,</span>
              <span>
                Are we
                <span className="bold-letters">the creators, or creations</span>
                in the night?
              </span>
              <span>Lost in the mystery of our origin's spark,</span>
              <span>I question the purpose, the truth, and the dark.</span>
            </div>
          </div>
          <div className="for-vision-column"></div>
          <div className="for-vision-column">
            <div className="vision-para">
              <span>Torn between the inward and outward quest,</span>
              <span>The universe within, or the cosmos at best,</span>
              <span>I grapple with the struggle of which path to take,</span>
              <span>
                The eternal
                <span className="bold-letters">
                  journey of self, or the infinite space
                </span>
                to break.
              </span>
            </div>
            <div className="vision-para">
              <span>And so, I find solace in balance and grace,</span>
              <span>Embracing the dualities of life and space,</span>
              <span>For I am, in the end, both observer and observed,</span>
              <span>
                I, an Observer for the universe; Observed by the universe,
                <span className="bold-letters">forever conserved.</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="all-2d-html">
        <div className="header">
          <div className="header-left">
            <Logo />
          </div>
          <div className="header-center">
            {welcomeVisible && <WelcomeMessage />}
          </div>
          <div className="header-right">
            <div className="keypad">
              {keyPad &&
                Object.keys(keyPad).map((key) => {
                  return (
                    <div
                      className="button key-instr"
                      onClick={() => {
                        window.dispatchEvent(
                          new KeyboardEvent("keyup", { code: "Key" + key })
                        );
                      }}
                      key={key}
                    >
                      <div className="key">{key}</div>
                      <div className="key-rep">{keyPad[key]}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="center">
          {breatheGuide && (
            <>
              <div className="breathing-guide">
                <Tetradecagon joined={false} />
                <div className="breathing-guide-text"></div>
                <Tetradecagon joined={true} />
              </div>

              <div className="audio-selection">
                <div className="audio-question">
                  I, an Observer for the Universe,
                  <br></br>
                  Observed by the Universe.
                </div>
                <div className="audio-answer">
                  <div
                    className="button audio-button-1"
                    onClick={() => {
                      handleAudioSelection(true);
                    }}
                  >
                    REACH
                  </div>
                </div>
                <div className="credits">
                  <a
                    className="credits-a button"
                    href="https://www.pranavaa.com/static/credits.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    CREDITS
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default OnlyHTML;
