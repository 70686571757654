import { useGLTF, useAnimations } from "@react-three/drei";
import { forwardRef, useEffect } from "react";
const Model = forwardRef(function Model(
  { url, position, rotation, scale, name, Children, animation, hidden },
  ref
) {
  const model = useGLTF(url);
  const { actions } = useAnimations(model.animations, model.scene);
  let visible = true;
  if (hidden) {
    visible = false;
  }

  useEffect(() => {
    if (animation) {
      actions.Animation.play();
    }
  }, [animation, actions]);

  return (
    <group>
      <primitive
        object={model.scene}
        position={[position.x, position.y, position.z]}
        rotation={[rotation.x, rotation.y, rotation.z]}
        scale={scale}
        ref={ref}
        visible={visible}
      >
        {Children}
      </primitive>
    </group>
  );
});

export default Model;
