import React, { useEffect } from "react";
import { gsap } from "gsap";

const WelcomeMessage = ({ welcomeVisible }) => {
  useEffect(() => {
    let actual = [
      "नमस्ते",
      "ನಮಸ್ಕಾರ",
      "hello",
      "salut",
      "مرحبًا",
      "നമസ്കാരം",
      "ciao",
      "Привет",
      "こんにちは",
      "வணக்கம்",
      "నమస్కారం",
      "नमस्ते",
    ];
    let translation = [
      "Namaste",
      "Namaskāra",
      "Hello",
      "Salut",
      "Mrhban",
      "Namaskāraṁ",
      "Ciao",
      "Privet",
      "Kon'nichiwa",
      "Vaṇakkam",
      "Namaskāraṁ",
      "Namaste",
    ];
    gsap.to(".welcome", { autoAlpha: 1, duration: 2 });
    let bold = document.querySelector("div.welcome-bold");
    let small = document.querySelector("div.welcome-translate");
    let timelineWelcome = gsap.timeline({
      repeat: -1,
    });
    for (let i = 0; i < actual.length - 1; i++) {
      timelineWelcome.to(
        bold,
        {
          opacity: 0,
          y: -18,
          duration: 1,
          delay: 3,
          onComplete: () => {
            bold.innerHTML = actual[i + 1];
          },
        },
        ">"
      );
      timelineWelcome.to(
        small,
        {
          opacity: 0,
          y: 18,
          duration: 1,
          onComplete: () => {
            small.innerHTML = translation[i + 1];
          },
        },
        "<"
      );
      timelineWelcome.to(
        bold,
        {
          opacity: 1,
          y: 0,
          duration: 1,
        },
        ">"
      );
      timelineWelcome.to(
        small,
        {
          opacity: 1,
          y: 0,
          duration: 1,
        },
        "<"
      );
    }
  }, []);
  return (
    <div className="welcome">
      <div className="welcome-bold">नमस्ते</div>
      <div className="welcome-translate">Namaste</div>
    </div>
  );
};

export default WelcomeMessage;
