import { useEffect } from "react";
import { gsap } from "gsap";

const Logo = () => {
  useEffect(() => {
    let name2 = document.querySelector("span.name-span-2");
    let p = document.querySelector("div.name-div-p");
    let a = document.querySelectorAll("span.name-span-a");

    let timelineName = new gsap.timeline({
      yoyo: true,
      repeat: -1,
      delay: 9,
      repeatDelay: 9,
    });

    for (let i = 0; i < a.length; i++) {
      timelineName.to(a[i], { opacity: 0, duration: 0.5 }, ">");
    }
    timelineName.fromTo(
      name2,
      { y: -10, opacity: 0 },
      { duration: 1, opacity: 1, y: 0 },
      ">"
    );
    timelineName.fromTo(p, { y: 0 }, { y: -3.6, duration: 1 }, "<");
  }, []);

  return (
    <>
      <span className="name-span-1 name-span">
        <div className="name-div-p">p</div>
        <span>ranav</span>
        <span className="name-span-a">a</span>
        <span className="name-span-a">a</span>
      </span>
      <span className="name-span-2 name-span">Bharadwaj</span>
    </>
  );
};

export default Logo;
