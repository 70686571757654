import React from "react";

const Tetradecagon = ({ joined }) => {
  return joined ? (
    <svg
      className="tetradecagon joined"
      xmlns="http://www.w3.org/2000/svg"
      width="783.77"
      height="803.4"
      viewBox="0 0 783.77 803.4"
    >
      <polygon
        className="tetradecagon-polygon"
        points="78.61 152.15 1.5 312.73 1.73 490.95 79.07 651.36 218.48 762.35 392.22 801.9 565.8 762.12 705.04 651.14 782.27 490.73 782.21 312.45 704.93 152.04 565.63 41.05 392.05 1.5 217.74 40.88 78.61 152.15"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </svg>
  ) : (
    <svg
      className="tetradecagon unjoined"
      xmlns="http://www.w3.org/2000/svg"
      width="783.77"
      height="803.4"
      viewBox="0 0 783.77 803.4"
    >
      <line
        className="tetradecagon-test tetradecagon-line-1"
        x1="392.05"
        y1="1.5"
        x2="565.63"
        y2="41.05"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
      />
      <line
        className="tetradecagon-test tetradecagon-line-2"
        x1="704.93"
        y1="152.04"
        x2="565.63"
        y2="41.05"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
      />
      <line
        className="tetradecagon-test tetradecagon-line-3"
        x1="782.21"
        y1="312.45"
        x2="704.93"
        y2="152.04"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
      />
      <line
        className="tetradecagon-test tetradecagon-line-4"
        x1="782.27"
        y1="490.73"
        x2="782.21"
        y2="312.45"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="30"
      />
      <line
        className="tetradecagon-test tetradecagon-line-5"
        x1="705.04"
        y1="651.14"
        x2="782.27"
        y2="490.73"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
      />
      <line
        className="tetradecagon-test tetradecagon-line-6"
        x1="565.8"
        y1="762.12"
        x2="705.04"
        y2="651.14"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
      />
      <line
        className="tetradecagon-test tetradecagon-line-7"
        x1="392.22"
        y1="801.9"
        x2="565.8"
        y2="762.12"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
      />
      <line
        className="tetradecagon-test tetradecagon-line-8"
        x1="218.48"
        y1="762.35"
        x2="392.22"
        y2="801.9"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
      />
      <line
        className="tetradecagon-test tetradecagon-line-9"
        x1="79.07"
        y1="651.36"
        x2="218.48"
        y2="762.35"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
      />
      <line
        className="tetradecagon-test tetradecagon-line-10"
        x1="1.73"
        y1="490.95"
        x2="79.07"
        y2="651.36"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
      />
      <line
        className="tetradecagon-test tetradecagon-line-11"
        x1="1.5"
        y1="312.73"
        x2="1.73"
        y2="490.95"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="30"
      />
      <line
        className="tetradecagon-test tetradecagon-line-12"
        x1="78.61"
        y1="152.15"
        x2="1.5"
        y2="312.73"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
      />
      <line
        className="tetradecagon-test tetradecagon-line-13"
        x1="217.74"
        y1="40.88"
        x2="78.61"
        y2="152.15"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
      />
      <line
        className="tetradecagon-test tetradecagon-line-14"
        x1="392.05"
        y1="1.5"
        x2="217.74"
        y2="40.88"
        fill="none"
        stroke="#6c6c6c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="18"
      />
    </svg>
  );
};

export default Tetradecagon;
