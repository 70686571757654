import { PerspectiveCamera } from "@react-three/drei";

const Camera = () => {
  return (
    <PerspectiveCamera
      makeDefault
      position={[0, 0, 0]}
      rotation={[0, 0, 0]}
      fov={45}
    />
  );
};

export default Camera;
