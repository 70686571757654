import React from "react";
import { Line, Text, Sphere } from "@react-three/drei";

const Option = ({
  points,
  text,
  textPosition,
  color,
  clickhandler,
  pointerColor,
  sound,
}) => {
  const pointerEnterHandler = (event) => {
    event.eventObject.color = pointerColor || color;
    sound.current.play();
  };

  const pointerLeaveHandler = (event) => {
    event.eventObject.color = color;
  };
  return (
    <>
      <Line points={points} color={color} lineWidth={1} />
      <Sphere position={points[0]} color={color} scale={0.05} />
      <Text
        color={color}
        position={textPosition}
        fontSize={0.17}
        font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
        onClick={clickhandler}
        onPointerEnter={pointerEnterHandler}
        onPointerLeave={pointerLeaveHandler}
        name={text}
      >
        {text}
      </Text>
    </>
  );
};

export default Option;
