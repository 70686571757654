import React, { useRef } from "react";
import Option from "./Option";

const Menu = ({ menu, selectionHandler }) => {
  const OptionOneAudio = useRef(new Audio("Assets/Audio/optionOne.mp3"));
  const OptionTwoAudio = useRef(new Audio("Assets/Audio/optionTwo.mp3"));
  const OptionThreeAudio = useRef(new Audio("Assets/Audio/optionThree.mp3"));
  const OptionFourAudio = useRef(new Audio("Assets/Audio/optionFour.mp3"));

  return (
    <>
      {menu && (
        <>
          <Option
            points={[
              [0.25, -0.36, -7.5],
              [0.45, 0.5, -7.5],
              [1, 0.5, -7.5],
            ]}
            textPosition={[0.72, 0.6, -7.5]}
            color="#faedca"
            text="VISION"
            clickhandler={selectionHandler}
            pointerColor="#ffcd45"
            sound={OptionOneAudio}
          />
          <Option
            points={[
              [-1.7, -1.5, -9],
              [-1.9, -0.7, -9],
              [-2.8, -0.7, -9],
            ]}
            textPosition={[-2.35, -0.6, -9]}
            color="#faedca"
            text="INTELLECT"
            clickhandler={selectionHandler}
            pointerColor="#ffcd45"
            sound={OptionTwoAudio}
          />
          <Option
            points={[
              [1.7, -2.2, -8.1],
              [1.9, -1.8, -8.1],
              [3.15, -1.8, -8.1],
            ]}
            textPosition={[2.53, -1.7, -8.1]}
            color="#faedca"
            text="CONDITIONING"
            clickhandler={selectionHandler}
            pointerColor="#ffcd45"
            sound={OptionThreeAudio}
          />
          <Option
            points={[
              [-3, -1.2, -6.9],
              [-3.05, -1.0, -6.9],
              [-3.85, -1.0, -6.9],
            ]}
            textPosition={[-3.45, -0.9, -6.9]}
            color="#faedca"
            text="CONTACT"
            clickhandler={selectionHandler}
            pointerColor="#ffcd45"
            sound={OptionFourAudio}
          />
        </>
      )}
    </>
  );
};

export default Menu;
