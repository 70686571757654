import React from "react";
import { Html } from "@react-three/drei";

const Iframe = ({ src }) => {
  return (
    <>
      <Html
        transform
        wrapperClass="tabletscreen"
        distanceFactor={0.93}
        position={[0, -0.032, 0]}
      >
        <iframe title="Tablet-screen" src={src} />
      </Html>
    </>
  );
};

export default Iframe;
