import React, { useEffect } from "react";
import { Html } from "@react-three/drei";
import { useProgress } from "@react-three/drei";

const LoadingScreen = () => {
  const { progress } = useProgress();

  useEffect(() => {
    let percentagediv = document.querySelector(".load-percentage");
    if (percentagediv) {
      percentagediv.style.width = progress * 3.33 + "px";
    }
  }, [progress]);

  return (
    <Html>
      <div className="loading-message">
        <div className="loading-bar">
          <div className="to-load"></div>
          <div className="load-percentage"></div>
        </div>
      </div>
    </Html>
  );
};

export default LoadingScreen;
